
import React from "react"
import Layout from "../components/layout"
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="Cambodia | Kiwi & Cacahuate" description="A 2 month adventure made of nice people, wrapped in humidity and heat, and covered by a magical realism." url="https://kiwicacahuate.com/cambodia/" image="https://kiwicacahuate.com/cambodia/cambodia.jpg" imageWidth="3000" imageHeight="1895" />

    <div className="">
      <div className="story">
        <div className="photoset-section">

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="030">
                <source srcSet="/cambodia/cambodia.webp, /cambodia/cambodia.webp 2x" />
                <source srcSet="/cambodia/cambodia.jpg, /cambodia/cambodia.jpg 2x" />
                <img src="/cambodia/cambodia.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="dairy-title">Cambodia</h2>
            <div className="photoset-intro left-align">
              <p>Cambodia was an adventure made of nice people, wrapped in humidity and heat, and covered by a magical realism that felt as different as it did similar to that of Mexico (my country of origin). The reason to begin this journey was for me to complete a two-month internship, which I did working for a small entrepreneurial development agency (The Agile Group). During this time, Jeremy continued to work remotely in Phnom Penh (enduring weekly meetings at 4 am) while we both discovered new flavors, words, customs and places. This page is a visual document of the unique experience.</p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="002">
                <source srcSet="/cambodia/thumbnail/002.webp, /cambodia/thumbnail/002-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/002.jpg, /cambodia/thumbnail/002-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/002.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="003">
                <source srcSet="/cambodia/thumbnail/003.webp, /cambodia/thumbnail/003-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/003.jpg, /cambodia/thumbnail/003-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/003.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="005">
                <source srcSet="/cambodia/thumbnail/005.webp, /cambodia/thumbnail/005-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/005.jpg, /cambodia/thumbnail/005-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/005.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="001">
                <source srcSet="/cambodia/thumbnail/001.webp, /cambodia/thumbnail/001-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/001.jpg, /cambodia/thumbnail/001-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/001.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="007">
                <source srcSet="/cambodia/thumbnail/007.webp, /cambodia/thumbnail/007-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/007.jpg, /cambodia/thumbnail/007-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/007.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="006">
                <source srcSet="/cambodia/thumbnail/006.webp, /cambodia/thumbnail/006-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/006.jpg, /cambodia/thumbnail/006-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/006.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="019">
                <source srcSet="/cambodia/thumbnail/019.webp, /cambodia/thumbnail/019-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/019.jpg, /cambodia/thumbnail/019-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/019.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="diary-title">Killing Fields</h2>
            <div className="photoset-intro left-align">
              <p>Both the visit to the killings fields and to the Tuol Sleng Genocide Museum (previously the S-21 prison where multitudes spent their days in torture before being taken to the killing fields) where … you know. Heartbreaking. Impactful. Overwhelming at times. Millions of people were killed (men, women, children, foreigners) during the genocide of Cambodia in the 70s and these places have been kept, almost without alterations, as they were during the times of the dictatorship. However, going there reminds you that this is event does not stand by itself in history, not is the produce of one crazy guy. It was part of a world momentum that in this place became particularly horrific. The tours in both locations are completed mostly in silence and at each people own pace since they give you headphones and a recording, which helps everyone to immerse in the history, the remembrance and the humanity of it all. I am sure a good book can arouse a similar feeling and understanding, but visiting Phnom Penh allows us the opportunity to pay respects to those who could still be here and aren’t.</p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="015">
                <source srcSet="/cambodia/thumbnail/015.webp, /cambodia/thumbnail/015-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/015.jpg, /cambodia/thumbnail/015-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/015.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="017">
                <source srcSet="/cambodia/thumbnail/017.webp, /cambodia/thumbnail/017-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/017.jpg, /cambodia/thumbnail/017-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/017.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="016">
                <source srcSet="/cambodia/thumbnail/016.webp, /cambodia/thumbnail/016-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/016.jpg, /cambodia/thumbnail/016-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/016.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="018">
                <source srcSet="/cambodia/thumbnail/018.webp, /cambodia/thumbnail/018-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/018.jpg, /cambodia/thumbnail/018-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/018.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="012">
                <source srcSet="/cambodia/thumbnail/012.webp, /cambodia/thumbnail/012-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/012.jpg, /cambodia/thumbnail/012-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/012.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="013">
                <source srcSet="/cambodia/thumbnail/013.webp, /cambodia/thumbnail/013-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/013.jpg, /cambodia/thumbnail/013-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/013.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="020">
                <source srcSet="/cambodia/thumbnail/020.webp, /cambodia/thumbnail/020-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/020.jpg, /cambodia/thumbnail/020-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/020.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-story">
            <h2 className="dairy-title">Daily life</h2>
            <div className="photoset-intro left-align">
              <p>Though we’d heard about the intense traffic and difficulty to walk in some Southeast Asian countries, it is only by being there that you can fully appreciate the order in the chaos and the chaos in the order. Drivers understand the unspoken rules of the road, such as: Drive right across the traffic in the opposite lane if it means taking a shortcut and avoid going all the way (300m) to the U-turn. I am a firm believer in respecting different development structures. At the same time, I enjoy walking, and it was just really hard to practice it! Both Jeremy I suffered a little bit in this regard, especially because walking even one kilometer is an adventure that can often be painstaking and dangerous. Not dangerous as in "someone will rob you," but as in "a motorbike may hit you or fall into a ditch” at any given moment. I do understand that we were the foreigners trying to break the rules of the road: Don’t walk!</p>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="026">
                <source srcSet="/cambodia/thumbnail/026.webp, /cambodia/thumbnail/026-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/026.jpg, /cambodia/thumbnail/026-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/026.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="025">
                <source srcSet="/cambodia/thumbnail/025.webp, /cambodia/thumbnail/025-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/025.jpg, /cambodia/thumbnail/025-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/025.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="008">
                <source srcSet="/cambodia/thumbnail/008.webp, /cambodia/thumbnail/008-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/008.jpg, /cambodia/thumbnail/008-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/008.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="009">
                <source srcSet="/cambodia/thumbnail/009.webp, /cambodia/thumbnail/009-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/009.jpg, /cambodia/thumbnail/009-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/009.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="011">
                <source srcSet="/cambodia/thumbnail/011.webp, /cambodia/thumbnail/011-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/011.jpg, /cambodia/thumbnail/011-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/011.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="027">
                <source srcSet="/cambodia/thumbnail/027.webp, /cambodia/thumbnail/027-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/027.jpg, /cambodia/thumbnail/027-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/027.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="010">
                <source srcSet="/cambodia/thumbnail/010.webp, /cambodia/thumbnail/010-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/010.jpg, /cambodia/thumbnail/010-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/010.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="028">
                <source srcSet="/cambodia/thumbnail/028.webp, /cambodia/thumbnail/028-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/028.jpg, /cambodia/thumbnail/028-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/028.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="029">
                <source srcSet="/cambodia/thumbnail/029.webp, /cambodia/thumbnail/029-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/029.jpg, /cambodia/thumbnail/029-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/029.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="031">
                <source srcSet="/cambodia/thumbnail/031.webp, /cambodia/thumbnail/031-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/031.jpg, /cambodia/thumbnail/031-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/031.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="032">
                <source srcSet="/cambodia/thumbnail/032.webp, /cambodia/thumbnail/032-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/032.jpg, /cambodia/thumbnail/032-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/032.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="035">
                <source srcSet="/cambodia/thumbnail/035.webp, /cambodia/thumbnail/035-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/035.jpg, /cambodia/thumbnail/035-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/035.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="023">
                <source srcSet="/cambodia/thumbnail/023.webp, /cambodia/thumbnail/023-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/023.jpg, /cambodia/thumbnail/023-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/023.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="022">
                <source srcSet="/cambodia/thumbnail/022.webp, /cambodia/thumbnail/022-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/022.jpg, /cambodia/thumbnail/022-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/022.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="014">
                <source srcSet="/cambodia/thumbnail/014.webp, /cambodia/thumbnail/014-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/014.jpg, /cambodia/thumbnail/014-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/014.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="024">
                <source srcSet="/cambodia/thumbnail/024.webp, /cambodia/thumbnail/024-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/024.jpg, /cambodia/thumbnail/024-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/024.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="036">
                <source srcSet="/cambodia/thumbnail/036.webp, /cambodia/thumbnail/036-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/036.jpg, /cambodia/thumbnail/036-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/036.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="037">
                <source srcSet="/cambodia/thumbnail/037.webp, /cambodia/thumbnail/037-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/037.jpg, /cambodia/thumbnail/037-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/037.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="038">
                <source srcSet="/cambodia/thumbnail/038.webp, /cambodia/thumbnail/038-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/038.jpg, /cambodia/thumbnail/038-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/038.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="039">
                <source srcSet="/cambodia/thumbnail/039.webp, /cambodia/thumbnail/039-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/039.jpg, /cambodia/thumbnail/039-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/039.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="040">
                <source srcSet="/cambodia/thumbnail/040.webp, /cambodia/thumbnail/040-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/040.jpg, /cambodia/thumbnail/040-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/040.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="086">
                <source srcSet="/cambodia/thumbnail/086.webp, /cambodia/thumbnail/086-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/086.jpg, /cambodia/thumbnail/086-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/086.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="041">
                <source srcSet="/cambodia/thumbnail/041.webp, /cambodia/thumbnail/041-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/041.jpg, /cambodia/thumbnail/041-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/041.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="dairy-title">Siem Reap</h2>
            <div className="photoset-intro left-align">
              <p>40 dollars for a driver, a tour guide and a moving vehicle (not an Uber but still an excellent tuk-tuk equipped with water bottles) for a whole day? Yes, that’s possible when visiting Angkor Wat: The largest religious compound in the world, occupying an area of 162.6 hectares. The size of the place is one of the reasons why hiring a driver is the only way to move around the area. Of our two companions, I was even more fond of the driver, who told us that he was saving to study and pay for a tour-guide license (which can be expensive but it is necessary in order to provide tours, and it represents a good and stable source of income for those who get it). This meant, we realized, that his cut of the 40 dollars was significantly smaller than that of the tour guide.</p>

              <p>We learned this on our second day visiting the compound (some people take up to three days). By then, I perfectly understood why Cambodians are so proud of this site, as it is indeed extraordinary (this is coming from a Mexican that has visited the famous pyramids built by the Mayans, Mexicans and Huastecs. And even Machu Picchu in Peru). If I were to be asked, I would say that the most striking part, at least for me, was learning that each building was erected in different eras, thus reflecting the passage of time and the changes in history. You can find this in Wikipedia, but construction began in the 12th century, and the city continued to be inhabited until the 17th century.  The stones preserve the story of peace and wars; of changes in religions (Hinduism to Buddhism), culture, and even in fashion. If you are traveling there, you will come across many other websites with great recommendations, so our only one is to find a shadow to rest from the heat, sit for a moment and take it all in.</p>

              <p>Oh, maybe another thing. A 50 dollar room will get you a very nice place with pool and breakfast included in Siem Reap (a charming famous town located a few kilometers from Angkor Wat). A shorter budget will get you something good enough too. Siem Reap, by the way, looks very much like the “magic towns” in Mexico (it did to me anyway!)</p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="042">
                <source srcSet="/cambodia/thumbnail/042.webp, /cambodia/thumbnail/042-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/042.jpg, /cambodia/thumbnail/042-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/042.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="043">
                <source srcSet="/cambodia/thumbnail/043.webp, /cambodia/thumbnail/043-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/043.jpg, /cambodia/thumbnail/043-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/043.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="044">
                <source srcSet="/cambodia/thumbnail/044.webp, /cambodia/thumbnail/044-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/044.jpg, /cambodia/thumbnail/044-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/044.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="045">
                <source srcSet="/cambodia/thumbnail/045.webp, /cambodia/thumbnail/045-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/045.jpg, /cambodia/thumbnail/045-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/045.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="046">
                <source srcSet="/cambodia/thumbnail/046.webp, /cambodia/thumbnail/046-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/046.jpg, /cambodia/thumbnail/046-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/046.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="047">
                <source srcSet="/cambodia/thumbnail/047.webp, /cambodia/thumbnail/047-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/047.jpg, /cambodia/thumbnail/047-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/047.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="048">
                <source srcSet="/cambodia/thumbnail/048.webp, /cambodia/thumbnail/048-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/048.jpg, /cambodia/thumbnail/048-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/048.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="049">
                <source srcSet="/cambodia/thumbnail/049.webp, /cambodia/thumbnail/049-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/049.jpg, /cambodia/thumbnail/049-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/049.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="050">
                <source srcSet="/cambodia/thumbnail/050.webp, /cambodia/thumbnail/050-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/050.jpg, /cambodia/thumbnail/050-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/050.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="051">
                <source srcSet="/cambodia/thumbnail/051.webp, /cambodia/thumbnail/051-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/051.jpg, /cambodia/thumbnail/051-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/051.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="052">
                <source srcSet="/cambodia/thumbnail/052.webp, /cambodia/thumbnail/052-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/052.jpg, /cambodia/thumbnail/052-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/052.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="054">
                <source srcSet="/cambodia/thumbnail/054.webp, /cambodia/thumbnail/054-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/054.jpg, /cambodia/thumbnail/054-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/054.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="053">
                <source srcSet="/cambodia/thumbnail/053.webp, /cambodia/thumbnail/053-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/053.jpg, /cambodia/thumbnail/053-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/053.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="055">
                <source srcSet="/cambodia/thumbnail/055.webp, /cambodia/thumbnail/055-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/055.jpg, /cambodia/thumbnail/055-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/055.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="056">
                <source srcSet="/cambodia/thumbnail/056.webp, /cambodia/thumbnail/056-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/056.jpg, /cambodia/thumbnail/056-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/056.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="058">
                <source srcSet="/cambodia/thumbnail/058.webp, /cambodia/thumbnail/058-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/058.jpg, /cambodia/thumbnail/058-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/058.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="059">
                <source srcSet="/cambodia/thumbnail/059.webp, /cambodia/thumbnail/059-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/059.jpg, /cambodia/thumbnail/059-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/059.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="061">
                <source srcSet="/cambodia/thumbnail/061.webp, /cambodia/thumbnail/061-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/061.jpg, /cambodia/thumbnail/061-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/061.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="062">
                <source srcSet="/cambodia/thumbnail/062.webp, /cambodia/thumbnail/062-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/062.jpg, /cambodia/thumbnail/062-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/062.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="066">
                <source srcSet="/cambodia/thumbnail/066.webp, /cambodia/thumbnail/066-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/066.jpg, /cambodia/thumbnail/066-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/066.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="063">
                <source srcSet="/cambodia/thumbnail/063.webp, /cambodia/thumbnail/063-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/063.jpg, /cambodia/thumbnail/063-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/063.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="065">
                <source srcSet="/cambodia/thumbnail/065.webp, /cambodia/thumbnail/065-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/065.jpg, /cambodia/thumbnail/065-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/065.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="068">
                <source srcSet="/cambodia/thumbnail/068.webp, /cambodia/thumbnail/068-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/068.jpg, /cambodia/thumbnail/068-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/068.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="069">
                <source srcSet="/cambodia/thumbnail/069.webp, /cambodia/thumbnail/069-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/069.jpg, /cambodia/thumbnail/069-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/069.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="070">
                <source srcSet="/cambodia/thumbnail/070.webp, /cambodia/thumbnail/070-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/070.jpg, /cambodia/thumbnail/070-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/070.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="071">
                <source srcSet="/cambodia/thumbnail/071.webp, /cambodia/thumbnail/071-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/071.jpg, /cambodia/thumbnail/071-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/071.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="072">
                <source srcSet="/cambodia/thumbnail/072.webp, /cambodia/thumbnail/072-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/072.jpg, /cambodia/thumbnail/072-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/072.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="073">
                <source srcSet="/cambodia/thumbnail/073.webp, /cambodia/thumbnail/073-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/073.jpg, /cambodia/thumbnail/073-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/073.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="074">
                <source srcSet="/cambodia/thumbnail/074.webp, /cambodia/thumbnail/074-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/074.jpg, /cambodia/thumbnail/074-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/074.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>


          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="075">
                <source srcSet="/cambodia/thumbnail/075.webp, /cambodia/thumbnail/075-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/075.jpg, /cambodia/thumbnail/075-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/075.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="076">
                <source srcSet="/cambodia/thumbnail/076.webp, /cambodia/thumbnail/076-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/076.jpg, /cambodia/thumbnail/076-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/076.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="077">
                <source srcSet="/cambodia/thumbnail/077.webp, /cambodia/thumbnail/077-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/077.jpg, /cambodia/thumbnail/077-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/077.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="078">
                <source srcSet="/cambodia/thumbnail/078.webp, /cambodia/thumbnail/078-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/078.jpg, /cambodia/thumbnail/078-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/078.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="079">
                <source srcSet="/cambodia/thumbnail/079.webp, /cambodia/thumbnail/079-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/079.jpg, /cambodia/thumbnail/079-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/079.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="080">
                <source srcSet="/cambodia/thumbnail/080.webp, /cambodia/thumbnail/080-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/080.jpg, /cambodia/thumbnail/080-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/080.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="081">
                <source srcSet="/cambodia/thumbnail/081.webp, /cambodia/thumbnail/081-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/081.jpg, /cambodia/thumbnail/081-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/081.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="082">
                <source srcSet="/cambodia/thumbnail/082.webp, /cambodia/thumbnail/082-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/082.jpg, /cambodia/thumbnail/082-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/082.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="083">
                <source srcSet="/cambodia/thumbnail/083.webp, /cambodia/thumbnail/083-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/083.jpg, /cambodia/thumbnail/083-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/083.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="084">
                <source srcSet="/cambodia/thumbnail/084.webp, /cambodia/thumbnail/084-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/084.jpg, /cambodia/thumbnail/084-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/084.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="085">
                <source srcSet="/cambodia/thumbnail/085.webp, /cambodia/thumbnail/085-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/085.jpg, /cambodia/thumbnail/085-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/085.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="033">
                <source srcSet="/cambodia/thumbnail/033.webp, /cambodia/thumbnail/033-2x.webp 2x" />
                <source srcSet="/cambodia/thumbnail/033.jpg, /cambodia/thumbnail/033-2x.jpg 2x" />
                <img src="/cambodia/thumbnail/033.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

